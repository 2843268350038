import { HTTPService } from "./service";

//GET Greenscore
export const getGS = async ({ upcCode }) => {
  const params = {}

  if (upcCode) {
    params.upcCode = upcCode
  }

  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getTotalGS`,
    params: params,
  });
};

// GET PRODUCT DATA
export const getProductData = async ({ upcCode }) => {
  const params = {}

  if (upcCode) {
    params.upcCode = upcCode
    params.activegs = 0
  }

  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getProductData`,
    params: params,
  });
};

// GET OTHER PRODUCT DATA
export const getOtherProductData = async ({ productName }) => {
  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getProductOtherData?productName=${productName}&activegs=0`,
  });
};

// GET BETTER-FOR-YOU OPTIONS
export const getBFY = async ({ upcCode, client }) => {
  const queryParams = {
    upcCode,
    client
  };

  try {
    const response = await HTTPService({
      method: "GET",
      url: `/ProductTableMergeds/getBetterForYouProductsUPC`,
      params: queryParams,
    });
    return response;
  } catch (error) {
    console.error("Error fetching related products:", error);
    throw error;
  }
}

//Get scores
export const getScores = async ({ upcCode }) => {
  return await HTTPService({
    method: "GET",
    url: `/ProductTableMergeds/getAllGS?upcCode=${upcCode}`,
  });
};