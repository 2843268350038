import {
  getOtherProductData,
  getProductData,
} from "../../APIs/functions";
import Chart from "../../Components/Chart";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";
import { useEffect, useRef, useState } from "react";
import { Spin } from "antd";
import "../GSChart/gsChart.scss";
import ChartSkeleton from "./chartSkeleton";
import { sampleAdditionalData, sampleProduct } from "./sampleData";


const GSchartNative = () => {
  const queries = {
    retry: true,
    refetchOnmount: true,
    refetchOnWindowFocus: true,
    cacheTime: 325000,
    staleTime: 300000,
  };

  const [searchParams] = useSearchParams();
  let upcCode = searchParams.get("upcCode");

  const [productName, setProductName] = useState("");
  const [retryCount, setRetryCount] = useState(0);

  const chartRef = useRef(null);

  const {
    data: product,
    isLoading: productDataLoading,
    isError: productDataError,
    refetch,
    isFetching,
  } = useQuery(
    ["productData", upcCode],
    () => getProductData({ upcCode }),
    queries
  );

  const {
    data: productOtherData,
    isLoading: productOtherDataLoading,
    isError: productOtherDataError,
  } = useQuery(
    ["productOtherData", productName],
    () => getOtherProductData({ productName }),
    {
      ...queries,
      enabled: productName !== "",
    }
  );

  useEffect(() => {
    if (!productDataLoading) {
      // Check UPC code validity and handle errors
      if (upcCode.length !== 11 && upcCode.length !== 12) {
        console.log(`GS Chart is not available for UPC: ${upcCode}`);
        window.parent.postMessage("Chart not available", "*");
        return;
      }

      // Check if product data is available
      if (productDataError || !product) {
        // Retry fetching data with modified UPC code
        if (retryCount === 0) {
          let modifiedUpcCode = upcCode;
          if (upcCode.length === 12 && upcCode.startsWith("0")) {
            modifiedUpcCode = upcCode.substring(1);
          } else if (upcCode.length !== 11 && upcCode.length !== 12) {
            console.log(`GS Chart is not available for UPC: ${upcCode}`);
            window.parent.postMessage("Chart not available", "*");
            return;
          } else {
            modifiedUpcCode = "0" + upcCode;
          }
          upcCode = modifiedUpcCode; // Update UPC code
          refetch().then(() => {
            setRetryCount(retryCount + 1);
          });
        } else {
          // Notify parent window about chart unavailability
          console.log(`GS Chart is not available for UPC: ${upcCode}`);
          window.parent.postMessage("Chart not available", "*");
        }
      }

      setProductName(product?.productNameURL);
    }
  }, [productDataLoading, productDataError, product, upcCode, retryCount, refetch]);

  useEffect(() => {
    const sendHeight = () => {
      if (chartRef.current) {
        const height = chartRef.current.scrollHeight || chartRef.current.offsetHeight;
        window.parent.postMessage(height.toString(), "*");
        console.log(height);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      sendHeight();
    });

    if (chartRef.current) {
      resizeObserver.observe(chartRef.current);
    }

    window.addEventListener('resize', sendHeight);
    sendHeight();

    return () => {
      if (chartRef.current) {
        resizeObserver.unobserve(chartRef.current);
      }
      resizeObserver.disconnect();
      window.removeEventListener('resize', sendHeight);
    };
  }, []);

  return (
    <div ref={chartRef}>
      {product && productOtherData ?
        <div id={"gsChartExportWithoutHeight"}>
          <Chart label={null} product={product} additionalData={productOtherData} />
        </div>
        : <ChartSkeleton label={null} product={sampleProduct} additionalData={null} />}
    </div>
  );
};

export default GSchartNative;
